<template>
  <confirm-dialog hide-actions :show="dialog">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind:attrs="attrs" v-bind:on="on">
        <v-btn small icon class="pl-0 pr-0" v-bind="attrs" v-on="on">
          <v-img max-width="22px" :src="require(`@/assets/password.svg`)" />
        </v-btn>
      </slot>
    </template>
    <template #title>
      {{ $t("change-password") }}
    </template>

    <validation-observer v-slot="{ valid }">
      <v-form @submit.prevent="changePassword">
        <v-row>
          <v-col cols="12">
            {{
              $t("password-confirmation-question", { name: item.name, email: item.email })
            }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-2 pb-0">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('password').toLowerCase()"
              :rules="{ required: true }"
            >
              <v-text-field
                v-model="request.password"
                type="password"
                outlined
                :label="$t('password')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" class="py-0">
            <validation-provider
              v-slot="{ errors }"
              :name="$t('password-confirmation').toLowerCase()"
              :rules="{ required: true }"
            >
              <v-text-field
                v-model="request.confirm"
                type="password"
                outlined
                :label="$t('password-confirmation')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <v-btn block elevation="0" outlined color="red" @click.prevent="discard">
              {{ $t("discard") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="primary"
              type="submit"
              :disabled="!valid || request.password != request.confirm"
              elevation="0"
              block
            >
              {{ $t("submit") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog";
import users from "../../requests/users";

export default {
  components: {
    ConfirmDialog,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    dialog: false,
    request: {
      password: "",
      confirm: "",
    },
  }),
  created: async function () {},
  methods: {
    changePassword: async function () {
      try {
        await users.changeOtherPassword(
          this.item.id,
          this.request.password,
          this.request.confirm
        );
        await this.$store.dispatch("notificator/success", this.$t("succeeded_operation"));
        this.discard();
      } catch (e) {
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
    discard: async function () {
      this.dialog = true;
      this.request.password = "";
      this.request.confirm = "";
      setTimeout(
        function () {
          this.dialog = false;
        }.bind(this),
        500
      );
    },
  },
};
</script>
