<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("users") }}</h2>
      <p>{{ $t("users-.sub-heading") }}</p>
      <template #actions>
        <v-btn
          color="primary"
          elevation="0"
          @click.prevent="$router.push({ name: 'users-new' })"
        >
          <v-img max-width="22px" :src="require(`@/assets/plus.svg`)" class="mr-2" />
          {{ $t("users-.new") }}
        </v-btn>
      </template>
    </heading>
    <split-content>
      <template #auxiliar>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field v-model="filters.keyword" :label="$t('search')" outlined>
              <template #prepend-inner>
                <v-img max-width="20px" :src="require('@/assets/search.svg')" />
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0">
            <h4>{{ $t("filter-by") }}</h4>
          </v-col>
          <v-col cols="12" class="py-0">
            <h6>{{ $t("status") }}</h6>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-checkbox
              v-model="filters.active"
              :value="-1"
              :label="$t('all')"
              hide-details
              class="my-0"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-checkbox
              v-model="filters.active"
              value="1"
              :label="$t('active')"
              hide-details
              class="my-0"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-checkbox
              v-model="filters.active"
              value="0"
              :label="$t('disabled')"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col cols="12" class="mt-5 py-0">
            <h6>{{ $t("access-level") }}</h6>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-checkbox
              v-model="filters.level"
              value="admin"
              :label="$t('level-.admin')"
              hide-details
              multiple
              class="my-0"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-checkbox
              v-model="filters.level"
              value="business"
              :label="$t('level-.business')"
              hide-details
              multiple
              class="my-0"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-checkbox
              v-model="filters.level"
              value="manager"
              :label="$t('level-.manager')"
              hide-details
              multiple
              class="my-0"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-checkbox
              v-model="filters.level"
              value="clinic"
              :label="$t('level-.clinic')"
              hide-details
              multiple
              class="my-0"
            />
          </v-col>
        </v-row>
      </template>
      <users-table :filters="filters" />
    </split-content>
  </v-container>
</template>

<script>
import Heading from "../components/Heading";
import SplitContent from "../components/SplitView";
import UsersTable from "./Table";

export default {
  mixins: [],
  components: {
    Heading,
    UsersTable,
    SplitContent,
  },
  data: () => ({
    filters: {
      level: [],
      keyword: "",
      active: -1,
      clinic: "",
    },
  }),
  computed: {},
  watch: {
    "filters.active": async function (value) {
      if (value == null) {
        this.filters.active = -1;
      }
    },
  },
  created: async function () {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
