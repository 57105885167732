<template>
  <v-row class="payment-filters">
    <v-col cols="12" class="py-0">
      <datatable
        :headers="headers"
        :table="users"
        headerless
        class="v-data-table--in-row-headers"
      >
        <template #[`item.name`]="{ item }">
          <span>{{ $t(`level-.${item.level}`) }}</span>
          <span>{{ item.name }}</span>
        </template>
        <template #[`item.email`]="{ item }">
          <span>{{ $t("email") }}</span>
          <span>{{ item.email }}</span>
          <span v-if="item.phone_number" class="fz-small">{{ item.phone_number }}</span>
        </template>
        <template #[`item.active`]="{ item }">
          <span>{{ $t("active") }}</span>
          <span>{{ $t(item.active ? "yes" : "no") }}</span>
        </template>
        <template #[`item.clinic`]="{ item }">
          <div v-if="item.clinic">
            <span>{{ $t("clinic") }}</span>
            <span>{{ $t(item.clinic.name) }}</span>
          </div>
        </template>
        <template #[`item.id`]="{ item }">
          <div
            class="payment-buttons svg-buttons svg-buttons--4"
            :data-id="item.id"
            style="white-space: nowrap"
          >
            <v-btn
              small
              icon
              class="ml-2 pl-0 pr-0"
              @click="$router.push({ name: 'users-details', params: { id: item.id } })"
            >
              <v-img max-width="22px" :src="require(`@/assets/details.svg`)" />
            </v-btn>
            <change-password-dialog :item="item" />
            <v-btn
              small
              icon
              color="success"
              class="pl-0 pr-0"
              @click="$router.push({ name: 'users-edit', params: { id: item.id } })"
            >
              <v-img max-width="22px" :src="require(`@/assets/edit.svg`)" />
            </v-btn>
            <remove-dialog :item="item" @removed="reload" />
          </div>
        </template>
      </datatable>
    </v-col>
  </v-row>
</template>

<script>
import Datatable from "../components/Datatable";
import ChangePasswordDialog from "../components/ChangePasswordDialog";
import users from "../../requests/users";
import RemoveDialog from "./RemoveDialog";

export default {
  props: {
    filters: {
      type: Object,
      default: null,
    },
  },
  mixins: [],
  components: {
    Datatable,
    RemoveDialog,
    ChangePasswordDialog,
  },
  data: () => ({
    params: {},
    users: {
      loading: false,
      options: {},
      items: [],
      totalItems: 0,
      draw: 0,
    },
    timeout: null,
  }),
  computed: {
    headers: function () {
      return [
        {
          text: "",
          align: "start",
          value: "name",
          sortable: true,
        },
        {
          text: "",
          value: "email",
          sortable: false,
        },
        {
          text: "",
          value: "clinic",
          sortable: false,
        },
        {
          text: "",
          value: "active",
          sortable: false,
        },
        {
          text: "",
          value: "id",
          sortable: false,
        },
      ];
    },
    cleanFilters: function () {
      const params = {};
      if (this.filters.active != -1) {
        params.active = this.filters.active;
      }
      if (this.filters.level.length > 0) {
        params.level = this.filters.level;
      }
      if (this.filters.keyword != "") {
        params.keyword = this.filters.keyword;
      }
      return params;
    },
  },
  watch: {
    filters: {
      handler: async function () {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        setTimeout(
          async function () {
            await this.reload();
          }.bind(this),
          1000
        );
      },
      deep: true,
    },
  },
  created: async function () {
    this.reload();
  },
  methods: {
    reload: async function () {
      const response = await users.load(this.cleanFilters);
      this.users.items = response.items;
      this.users.totalItems = response.total;
    },
  },
};
</script>

<style lang="scss" scoped></style>
